import React from 'react';
import { Layout, RichTextHTML, Section } from '@websites/components';
import { YellowBusinessContext } from '../contexts/YellowBusinessProvider';
import { CartContext } from '../contexts/CartProvider';

const AboutPage = () => {
  const { yellowBusiness, sections } = React.useContext(YellowBusinessContext);
  const cart = React.useContext(CartContext);

  const hasTheme = !!yellowBusiness?.website?.theme;

  return (
    <>
      {hasTheme && (
        <Layout
          isEcommerce
          yellowBusiness={yellowBusiness}
          sections={sections}
          cart={cart}
          pageName={sections?.aboutBusinessSection?.name}
        >
          {yellowBusiness?.description && (
            <Section section={{ name: 'About us' }}>
              <RichTextHTML>{yellowBusiness.description}</RichTextHTML>
            </Section>
          )}
        </Layout>
      )}
    </>
  );
};

export default AboutPage;
